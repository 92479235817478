import {Link} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';

import './Footer.scss';
export const Footer = () => {
  const appStore = useStore($appStore);

  return (
    <footer className="fadeIn">
      <div className="footer-1 padLR-l">
        <div className="container">
          <div className="column">
            <Link to="/epikoinwnia" title="Επικοινωνήστε μαζί μας" className="logo">
              ΕΠΙΚΟΙΝΩΝΙΑ
            </Link>
            <div className="contactText">
              Μπορείτε να επικοινωνήσετε μαζί μας
              <br /> στα παρακάτω τηλέφωνα και email.
            </div>
            <div className="contactInfo">
              <div className="contactInfo-column">
                <div className="contactInfo-row">
                  <svg className="contactInfo-row-icon">
                    <use xlinkHref="#location-icon" />
                  </svg>
                  <a href="https://goo.gl/maps/cFjwx4UEgzCv1SL29" target="_blank" rel="noopener noreferrer">
                    Ιπποκράτους 34, Αθήνα 106 80
                  </a>
                </div>
                <div className="contactInfo-row">
                  <svg className="contactInfo-row-icon">
                    <use xlinkHref="#email-icon" />
                  </svg>
                  <a href="mailto:dimitris.niafas@gmail.com">dimitris.niafas@gmail.com</a>
                </div>
              </div>
              <div className="contactInfo-column">
                <div className="contactInfo-row">
                  <svg className="contactInfo-row-icon">
                    <use xlinkHref="#phone-icon" />
                  </svg>
                  <a href="tel:2103634044">(+30) 210 36 34 044</a>
                </div>
                <div className="contactInfo-row">
                  <svg className="contactInfo-row-icon">
                    <use xlinkHref="#mobile-icon" />
                  </svg>
                  <a href="tel:6932464987">(+30) 693 24 64 987</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {appStore.expandFooter && (
        <div className="contactMap fadeIn">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3144.743402140041!2d23.7364216!3d37.983117199999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bd3775251555%3A0xd27c42d3862d2d87!2sIppokratous%2034%2C%20Athina%20106%2080!5e0!3m2!1sen!2sgr!4v1680432494898!5m2!1sen!2sgr"
            title="Χάρτης"
            width="100%"
            height="450"
          ></iframe>
        </div>
      )}
      <div className="footer-2 padLR-l">
        <div className="container">
          <div className="copyright">{`© ${new Date().getFullYear()} niafas-legal.gr - All Rights Reserved`}</div>
          <div className="footer-nav">
            <Link to={`/page/oroi-xrhshs`} className="footer-nav-item">
              Όροι χρήσης
            </Link>
            <Link to={`/page/politikh-aporrhtou`} className="footer-nav-item">
              Πολιτική απορρήτου
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
